<template>
  <CRow>
    <CCol cols="12" lg="12">
      <CCard class="mb-0">
        <CCardHeader>
          <CRow>
            <CCol cols="4" xl="4" class="pt-0 pb-0 text-left">
              {{$t('rewards.Rewards')}}
            </CCol>
            <CCol cols="8" xl="8" class="pt-0 pb-0 text-xl-right">
              <b-switch class="mb-0 mt-1 mt-xl-0" v-model="showRedeemed" size="is-small" @input="getRewards()">{{$t('rewards.Show_redeemed_rewards')}}</b-switch>
              <div class="mt-1 mt-xl-0 d-inline-block align-top">
                <CButton class="ml-1 link" color="primary" @click="newRewardModal = true; resetRewardData();">
                  <i class="fas fa-plus mr-1"/>
                  <span>{{$t('rewards.Add_reward')}}</span>
                </CButton>                
              </div>               
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <b-table class="data_table" :data="rewards" :striped="true" :hoverable="true" :mobile-cards="true" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-simple="isPaginationSimple" :pagination-position="paginationPosition">
                <template slot-scope="props">
                  <b-table-column field="title" :label="$t('rewards.Reward')" width="50%">
                    {{props.row.title}}
                  </b-table-column>
                  <!-- <b-table-column field="description" :label="$t('common.Description')" width="30%">
                    {{props.row.description}}
                  </b-table-column> -->
                  <b-table-column field="team_name" :label="$t('common.Team')" width="25%">
                    {{props.row.team_name}} ({{props.row.department_name}})
                  </b-table-column>
                  <b-table-column field="team_points" :label="$t('common.Coins')" width="10%">
                    {{props.row.team_points}}/{{props.row.amount_needed}}
                  </b-table-column>                  
                  <b-table-column field="actions" width="5%">
                    <CButton class="mr-2 d-inline-block" color="primary" @click="getRewardData(props.row.reward_id);">
                      <i class="fas fa-info-circle"/>
                    </CButton>
                  </b-table-column>
                  <b-table-column field="actions" width="5%">
                    <CButton v-if="props.row.redeemed == 0" class="d-inline-block" color="primary" @click="confirmModal = true; confirmModalData = props.row;" :disabled="props.row.team_points < props.row.amount_needed">
                      <i class="fas fa-exchange-alt"/>
                    </CButton>
                    <div v-else class="check">
                      <i class="fas fa-check mr-1"/><span>{{$t('rewards.Redeemed')}}</span>
                    </div>
                  </b-table-column>
                </template>
                <template slot="empty">
                  <div class="p-2 text-center">
                    <loadingSpinner mode="auto" v-if="!overviewDataLoaded" :content="$t('rewards.Loading_rewards')"/>
                    <span v-else>{{$t('rewards.No_rewards_found')}}</span>
                  </div>
                </template>                
              </b-table>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>      

      <b-modal class="rewards" :can-cancel="['x']" :active.sync="newRewardModal" :width="960" scroll="keep">
        <CCard class="d-block mb-0">
          <CCardHeader class="pb-0">
            {{$t('rewards.New_reward')}}
          </CCardHeader>
          <CCardBody class="w-100">
            <CRow>
              <CCol cols="12" lg="12" class="pt-0 pb-0">
                <CInput type="text" :label="$t('rewards.Reward_name')" v-model="newReward.title" required was-validated/>
              </CCol>
            </CRow>
            <CRow>
              <CCol cols="12" lg="12" class="pt-0 pb-0">                
                <label class="w-100">{{$t('rewards.Reward_description')}} <span class="countdown" v-if="remainingCount > 0">{{remainingCount}} {{$t('common.characters_remaining')}}</span></label>
                <CTextarea @input="countdown()" v-model="newReward.description" rows="3" :maxlength="maxCount" required was-validated/>
              </CCol>
            </CRow>
            <CRow class="mb-3">
              <CCol cols="12" lg="12" class="pt-0 pb-0">              
                <label>{{$t('rewards.Intended_for_teams')}}</label>
                <multiselect
                  class="data_table"
                  v-model="newReward.team" 
                  :options="departments" 
                  :multiple="false"
                  :group-select="false"
                  group-values="teams" 
                  group-label="department_name"            
                  :placeholder="$t('Search_for_a') + ' ' + $t('common.team')" 
                  :selectLabel="$t('common.Add_team')" 
                  :selectGroupLabel="$t('common.Add_department')" 
                  :selectedLabel="$t('Added')"
                  :deselectLabel="$t('common.Remove_team')"
                  :deselectGroupLabel="$t('common.Remove_department')"  
                  track-by="team_name" 
                  label="team_name">
                  <span slot="noResult">{{$t('common.no_teams_found')}}</span>
                </multiselect>
              </CCol>
            </CRow>
            <CRow>
              <CCol cols="12" lg="12" class="pt-0 pb-0">                
                <CInput type="number" step="5" :label="$t('rewards.Points_to_redeem')" min="0" max="1000" v-model="newReward.amount_needed" required was-validated/>
              </CCol>            
            </CRow>    
            <CRow>
              <CCol cols="6" lg="6" class="pt-0 pb-0">
                <label>{{$t('rewards.Upload_image')}}</label>
                <image-uploader class="image_upload" :preview="true" :className="['fileinput', { 'fileinput--loaded': newRewardImageUploaded }]" capture="environment" :debug="0" doNotResize="gif" :autoRotate="true" outputFormat="verbose" @input="setRewardImage">
                  <label for="fileInput" slot="upload-label" class="m-0">
                    <span class="upload-caption btn btn-primary m-0"><i class="fas fa-file-upload mr-1"/>{{newRewardImageUploaded ? $t('common.Replace_image') : $t('common.Upload_image')}}</span>
                  </label>
                </image-uploader>
              </CCol>            
            </CRow>    
          </CCardBody>
          <CCardFooter>
            <div>
              <CButton color="primary" @click="insertReward()"><i class="fas fa-save mr-1"/>{{$t('Save')}}</CButton>
              <CButton color="secondary" @click="newRewardModal = false"><i class="fas fa-times mr-1"/>{{$t('cancel')}}</CButton>
            </div>
          </CCardFooter>          
        </CCard>
      </b-modal>

      <b-modal class="rewards" :can-cancel="['x']" :active.sync="confirmModal" :width="960" scroll="keep">
        <CCard class="mb-0">
          <CCardHeader class="pb-0">
            {{$t('rewards.Confirm_redeeming')}} {{confirmModalData.title}}
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol cols="12" lg="12" class="pt-0 pb-0">
                <p class="mb-2">{{$t('rewards.are_you_sure_to_redeem')}} {{confirmModalData.title}}?</p>
                <p class="mb-0">{{ $t('rewards.this_will_cost_the_team') }} {{confirmModalData.amount_needed}} {{ $t('rewards.points_from_team') }} {{confirmModalData.team_name}}. {{ $t('rewards.After_redeeming_team') }} {{confirmModalData.team_name}} {{ $t('rewards.will_have') }} {{confirmModalData.team_points - confirmModalData.amount_needed}} {{ $t('rewards.points_left') }}.</p>
              </CCol>            
            </CRow>    
          </CCardBody>
          <CCardFooter>
            <CButton color="primary" @click="redeemReward(confirmModalData.reward_id, confirmModalData)"><i class="fas fa-check mr-1"/>{{ $t('rewards.redeem') }}</CButton>
            <CButton color="secondary" @click="confirmModal = false"><i class="fas fa-times mr-1"/>{{$t('cancel')}}</CButton>
          </CCardFooter>          
        </CCard>
      </b-modal>

      <b-modal class="rewards" :can-cancel="['x']" :active.sync="detailsModal" :width="960" scroll="keep">
        <CCard class="mb-0">
          <CCardHeader class="pb-0">
            {{detailsModalData.title}}
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol cols="6" lg="6" md="6" sm="12" class="pt-0 pb-lg-0">                
                <p class="mb-2"><strong>{{$t('rewards.Progress_points_earned_by')}} {{detailsModalData.team_name}}</strong></p>
                <CChartLine class="chart" :datasets="chartData.datasets" :options="chartData.options" :labels="chartData.labels"/>
              </CCol>                           
              <CCol cols="6" lg="6" md="6" sm="12" class="pt-0 pb-0">
                <div v-if="detailsModalData.description">
                  <p class="mb-1"><strong>{{$t('common.Description')}}</strong></p>
                  <p class="mb-2">{{detailsModalData.description}}</p>
                </div>
                <div>
                  <p class="mb-1"><strong>{{$t('common.Details')}}</strong></p>
                  <p class="mb-2">
                    {{$t('rewards.The_reward')}} {{detailsModalData.title}} {{$t('rewards.has_been_created_for')}} {{detailsModalData.team_name}} ({{detailsModalData.department_name}}) {{$t('on')}} {{detailsModalData.date_created | moment("dddd DD-MM-YYYY HH:mm")}}.
                    {{$t('rewards.To_redeem_this_reward')}} {{detailsModalData.redeemed === 0 ? $t('rewards.has_to_earn') : $t('rewards.had_to_earn')}} {{detailsModalData.amount_needed}} {{$t('rewards.points_in_total')}}
                  </p>
                </div>
                <div>
                  <p class="mb-1"><strong>{{$t('common.Status')}}</strong></p>
                  <div v-if="detailsModalData.redeemed === 0">
                    <p class="mb-0" v-if="detailsModalData.amount_needed > detailsModalData.team_points">{{detailsModalData.team_name}} {{$t('rewards.still_has_to_earn')}} {{detailsModalData.amount_needed - detailsModalData.team_points}} {{$t('rewards.points_to_redeem')}} {{detailsModalData.title}}.</p>
                    <p class="mb-0" v-else>{{detailsModalData.team_name}} {{$t('rewards.has_earned_enough_points')}} {{detailsModalData.title}} ({{detailsModalData.team_points}} / {{detailsModalData.amount_needed}}).</p>
                  </div>
                  <div v-else>
                    <p class="mb-2">{{$t('rewards.The_reward')}} {{detailsModalData.title}} {{$t('rewards.has_been_redeemed_on')}} {{detailsModalData.date_redeemed | moment("dddd DD-MM-YYYY HH:mm")}}.</p>
                  </div>
                </div>
              </CCol>
            </CRow>    
          </CCardBody>
          <CCardFooter>
            <CButton color="secondary" @click="detailsModal = false"><i class="fas fa-times mr-1"/>{{$t('close')}}</CButton>
          </CCardFooter>          
        </CCard>
      </b-modal>              
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';
import Multiselect from 'vue-multiselect'
import ImageUploader from 'vue-image-upload-resize';
import { CChartLine } from '@coreui/coreui-vue-chartjs'
import loadingSpinner from '@/components/common/loadingSpinner.vue'

export default {
  name: 'Rewards',
  components: {
    QuickEdit,
    Multiselect,
    ImageUploader,
    CChartLine,
    loadingSpinner
  },
  data() {
    return {
      rewards: [],
      overviewDataLoaded: false,
      teams: [],
      departments: [],
      isPaginated: true,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      newReward: {},
      newRewardModal: false,
      newRewardImageUploaded: false,
      confirmModal: false,
      confirmModalData: {},
      detailsModal: false,
      detailsModalData: {},
      maxCount: 250,
      remainingCount: 250,
      showRedeemed: false,
      chartData: {        
        datasets: [],
        labels: [],
        options: {
          maintainAspectRatio: false,
          legend: {display: false},
          scales: {
            xAxes: [{
              gridLines: {drawOnChartArea: false},
              ticks: {display: false}
            }],
            yAxes: [{
              ticks: {
                display: true,
                beginAtZero: true,
                stepSize: 200
              },
              gridLines: {display: true}
            }]
          },
          elements: {
            point: {
              radius: 0,
              hitRadius: 10,
              hoverRadius: 4,
              hoverBorderWidth: 3
            }
          }
        }
      }
    }
  },
  methods: {
    insertReward () {
      let params = {};
      params = this.newReward;
      params.team_id = params.team.team_id;

      let title = params.title;
      let description = params.description;
      let team_id = params.team_id;
      let amount_needed = params.amount_needed;

      if(title && description && team_id && amount_needed) {
        axios.post(process.env.VUE_APP_API_URL + '/v1/loyalty/reward', params)
        .then(res => {
          this.$buefy.toast.open({ message: this.$t('rewards.Reward_added'), type: 'is-success', duration: 2000 });
          this.getRewards();
          this.resetRewardData();
          this.newRewardModal = false;
        })
        .catch(err => {
          console.error(err); 
          this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
        })
      } else {
        this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
      }
    },
    getRewards () {
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/rewards')
      .then(res => {
        this.rewards = res.data.data;
        this.overviewDataLoaded = true;
        // If redeemed rewards must be hidden, only show rewards where redeemded value is 0
        if(!this.showRedeemed) {
          this.rewards = this.rewards.filter( i => [0].includes( i.redeemed ) );
        }
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getDatasets() {
      const points = []
      // const target = []
      
      let index = 0;
      for (index; index < this.detailsModalData.reward_points_progress.length; index++) {
        points.push(this.detailsModalData.reward_points_progress[index]['team_points'])
        // target.push(this.detailsModalData.amount_needed)
      }

      if(this.detailsModalData.reward_points_progress.length === 1) {
        points.push(this.detailsModalData.reward_points_progress[0]['team_points'])
        // target.push(this.detailsModalData.amount_needed)
      }
    
      // Update chartData datasets
      this.chartData.datasets = [{
        label: this.$t('common.Coins'),
        backgroundColor: 'transparent',
        borderColor: getComputedStyle(document.documentElement).getPropertyValue('--DashboardLightGreen'),
        pointHoverBackgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--DashboardLightGreen'),
        borderWidth: 3,
        data: points
      // },{
      //   label: this.$t('rewards.Points_needed'),
      //   backgroundColor: 'transparent',
      //   borderColor: getComputedStyle(document.documentElement).getPropertyValue('--DashboardPrimary'),
      //   pointHoverBackgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--DashboardPrimary'),
      //   borderWidth: 2,
      //   data: target
      }]
    },
    getLabels() {
      const labels = []      
      // Get labels for last for weeks
      let index = 0;
      for (index; index < this.detailsModalData.reward_points_progress.length; index++) {
        labels.push(this.detailsModalData.reward_points_progress[index]['date'])        
      }

      if(this.detailsModalData.reward_points_progress.length === 1) {
        labels.push(this.detailsModalData.reward_points_progress[0]['date'])
      }      
      // Update chartData labels
      this.chartData.labels = labels;
    },       
    getRewardData (id) {
      let rewardId = id;
      axios.get(process.env.VUE_APP_API_URL + '/v1/loyalty/reward/' + rewardId)
      .then(res => {
        this.detailsModalData = res.data.data;
        // Gather the datasets for the chart
        this.getDatasets();
        // Gather the labels for the chart
        this.getLabels();
        // Open the details modal
        this.detailsModal = true;
      })
      .catch(err => {
        console.error(err);
      });
    },
    redeemReward (id, data) {
      let rewardId = id;
      let params = {};
      params.team_id = data.team_id;
      params.amount_needed = data.amount_needed;
      params.points_type_tag = 'reward_redeemed';

      axios.put(process.env.VUE_APP_API_URL + '/v1/loyalty/reward/' + rewardId + '/redeem', params)
      .then(res => {
        this.$buefy.toast.open({ message: this.$t('rewards.Reward_redeemed'), type: 'is-success', duration: 2000 });        
        this.getRewards();
        this.confirmModal = false;
      })
      .catch(err => {
        console.error(err); 
        this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
      });
    },    
    resetRewardData () {
      this.newRewardImageUploaded = false;
      this.newReward = {
        title: '',
        description: '',
        team_id: null,
        amount_needed: null,
        image: null
      }
    },
    setRewardImage (output) {
      this.newRewardImageUploaded = true;
      this.newReward.image = output.dataUrl;      
    },
    countdown () {
      this.remainingCount = this.maxCount - this.newReward.description.length;
    }
  },
  mounted: function(){    
    this.getRewards();
    this.resetRewardData();
            
    axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/departmentTeams/my')
    .then(res => {
      // Get management result from API response                
      var departmentTeams = res.data.data;

      // Empty departments array
      this.departments = [];        

      // Loop through management result and add unique departments to departments array
      var lookup = {};
      for (var departmentTeam, index = 0; departmentTeam = departmentTeams[index++];) {
        var departmentId = departmentTeam.department_id;

        if (!(departmentId in lookup)) {
          lookup[departmentId] = 1;
          this.departments.push({
            department_id: departmentTeam.department_id,
            department_name: departmentTeam.department_name,
            teams: []
          });
        }
      }

      for(var d = 0; d < this.departments.length; d++) {
        // Loop through management result
        for(var t = 0; t < departmentTeams.length; t++) {
          // Check if a management result is part of a department
          if(this.departments[d].department_id === departmentTeams[t].department_id) {
            // If so, add the management result data into the teams array of this department
            this.departments[d].teams.push({
              team_id: departmentTeams[t].team_id,
              team_name: departmentTeams[t].team_name,
            });
          }
        }
      }
    })
    .catch(err => {
      console.error(err); 
    });
  }
}
</script>

<style>
  #fileInput {
    display: none;
  }
</style>